import React from 'react';
import { Button, Flex, Text, TextLink } from '@ascension/web';
import { useHistory } from 'react-router-dom';
import { AscensionLogoWithName } from '../AscensionLogo/AscensionLogoWithName';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import TermsOfUseModal from '../TermsOfUse/TermsOfUseModal';
import HistoryLocationState from '../../interfaces/HistoryLocationState';
import { useAuthMethods, useIsLoggedIn } from '@ascension/ascensionid-sdk';
import { useAuthBaseContext } from '../AuthBase/AuthBase';
import { useSelector } from 'react-redux';
import { AuthStatus } from '@interfaces/Auth/AuthStatus';
import { AuthSelector } from '@store/selectors/AuthSelector';
import { AscensionLogoLoader } from '../AscensionLogo/AcensionLogoLoader';
import { APP_NAME, LOGIN_NOTIFICATION_BANNER_NOT_AFTER } from '@utilities/constants';
import { makeStyles } from '@material-ui/core/styles';
import LoginNotificationBanner from './LoginNotificationBanner';
import { ModalContextProvider, useModalContext } from '@components/Modal/ModalContextProvider';
import { TERMS_OF_USE_MODAL } from '@components/Modal/modalConstants';
import { ROUTE_CONSTANTS } from '@utilities/RouteConstants';
import { UnAuthenticatedBanner } from '@components/UnAuthenticatedBanner/UnAuthenticatedBanner';
import { BannerEnabledOptions } from '@components/AuthenticatedBanner/bannerConstants';

const getBodyClass = () => {
	const bodyElement = document.querySelector('body');
	if (!bodyElement) { return ''; }
	return bodyElement.className;
};

const setBodyClass = (className?: string) => {
	const bodyElement = document.querySelector('body');
	if (!bodyElement) { return; }
	bodyElement.className = className || '';
};

const useStyles = makeStyles((theme) => ({
	loginButton: {
		width: '169px',
		height: '48px',
		paddingTop: theme.spacing(2),
		margin: theme.spacing(2, 0),

	},
	bodyImage: {
		backgroundAttachment: 'fixed',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundImage: 'url(/assets/Login_Background.png)',
	},
	notificationBanner: {
		margin: theme.spacing(1),
	},
	tos: {
		fontSize: '12px',
		margin: theme.spacing(2),
	},
	appTitle: {
		fontSize: '2.5rem',
		color: 'white',
		fontFamily: 'HCo Whitney SSm'
	},
	bannerContainer: {
		margin: theme.spacing(0),
		marginBottom: theme.spacing(3)
	}
}));

const LoginInner = () => {
	const history = useHistory<HistoryLocationState>();

	usePageEffects('Login');

	const { authStatus } = useSelector(AuthSelector);
	const authUnknown = authStatus === AuthStatus.UNKNOWN;

	const { navToRef } = useAuthBaseContext();

	const isLoggedIn = useIsLoggedIn();

	const { login } = useAuthMethods();

	const navigateToIdProvider = React.useCallback(() => {
		void login({ state: btoa(JSON.stringify(navToRef.current)) });
	}, [login, navToRef]);

	const classes = useStyles();

	const originalClassNameRef = React.useRef<string>();
	React.useLayoutEffect(() => {
		originalClassNameRef.current = getBodyClass();

		setBodyClass(classes.bodyImage);

		return () => setBodyClass(originalClassNameRef.current);
	}, [classes]);

	React.useEffect(() => {
		if (!isLoggedIn) { return; }

		history.replace(navToRef.current || ROUTE_CONSTANTS.HOME);
	}, [isLoggedIn, history, navToRef]);

	const { openModal } = useModalContext();
	const { LOGIN_BANNER_ENABLED } = window.config;

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			minHeight="75vh"
			flexDirection="column"
		>
			{authUnknown && <div id="cover-spin"><AscensionLogoLoader/></div>}
			<Flex
				data-section="title"
				alignItems="center"
				justifyContent="center"
				pb={30}
				mt={120}
			>
				<Text
					className={classes.appTitle}
				>
					{APP_NAME}
				</Text>
			</Flex>
			{LOGIN_BANNER_ENABLED === BannerEnabledOptions.ENABLED &&
				<Flex
					width={[.9, .5]}
					maxWidth="760px"
				>
					<UnAuthenticatedBanner className={classes.bannerContainer} />
				</Flex>
			}
			<Flex
				backgroundColor="white"
				width={[.9, .5]}
				p={10}
				style={{ borderRadius: 4 }}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				data-section="main"
				maxWidth="760px"
				minHeight="268px"
			>
				<Text
					fontWeight={2}
					fontSize={26}
					pb={8}
					textAlign="center"
					data-section="section-title"
				>
					Welcome to Ascension {APP_NAME}
				</Text>
				<LoginNotificationBanner
					className={classes.notificationBanner}
					notAfter={LOGIN_NOTIFICATION_BANNER_NOT_AFTER}
				/>
				<Button
					className={classes.loginButton}
					data-field="login"
					onClick={navigateToIdProvider}
					pt={16}
				>
					<Flex
						flexDirection="row"
						justifyContent="center"
						alignItems="center"
					>

						<Text >LOG IN</Text>
					</Flex>
				</Button>
				<TextLink className={classes.tos} onClick={() => openModal(TERMS_OF_USE_MODAL)} fontSize="12px" data-field="termsOfUseLink">TERMS OF USE</TextLink>
			</Flex>
			<Flex
				data-section="title"
				alignItems="center"
				justifyContent="center"
				pb={30}
				mt={30}
			>
				<AscensionLogoWithName/>
			</Flex>
			<TermsOfUseModal/>
		</Flex>
	);
};

export const Login: React.FC = () => {
	return (
		<ModalContextProvider>
			<LoginInner/>
		</ModalContextProvider>
	);
};
