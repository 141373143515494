import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const useStyles =  makeStyles({
	guardImage: {
		height: 100,
		width: 'max-content',
		minWidth: 100,
		marginRight: 10,
		marginTop: 15,
		objectFit: 'contain',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundImage: 'url("/assets/security-alert-badge.png")',
	},
});

export const SecurityAlertBadge = () => {
	const classes = useStyles();
	return <Box className={classes.guardImage} data-testid="security-alert-badge" />;
};
