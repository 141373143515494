import React from 'react';
import { Banner } from '@components/Banner/Banner';
import { useSelector } from 'react-redux';
import { UserProfileDetailsSelector } from '@store/selectors/UserProfileSelector';
import { bannerConstants } from '@components/AuthenticatedBanner/bannerConstants';
import { checkBannerTimeActive } from './checkBannerTimeActive';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CopyText from '@components/CopyText/CopyText';
import { SecurityAlertBadge } from '@components/SecurityAlertBadge/SecurityAlertBadge';

const useStyles = makeStyles((theme) => ({
	bodyInfo: {
		marginBottom: theme.spacing(1),
	},
	copyTextClass: {
		flex: 'auto 1 1',
		flexDirection: 'row-reverse',
		justifyContent: 'flex-end',
		width: '100px',
		gap: '5px',
		fontWeight: 600
	},
	copyIcon: {
		height: '18px',
		width: '18px',
	},
}));

export const AuthenticatedBanner: React.FC = () => {
	const classes = useStyles();

	const { adEmail } = useSelector(UserProfileDetailsSelector);

	const [showBanner, setShowBanner] = React.useState(true);

	React.useEffect(() => {
		const hideBanner = sessionStorage.getItem('showAuthenticatedBanner') === 'false';
		const bannerExpiration = window.config.AUTHENTICATED_BANNER_EXPIRATION;
		setShowBanner(!!(!hideBanner && bannerExpiration && checkBannerTimeActive(bannerExpiration)));
	}, []);

	const handleClose = React.useCallback(() => {
		setShowBanner(false);
		sessionStorage.setItem('showAuthenticatedBanner', 'false');
	}, []);

	if (!showBanner) {
		return null;
	}

	return (
		<Banner
			img={SecurityAlertBadge}
			title={bannerConstants.TITLE}
			onClose={handleClose}
		>
			{adEmail && <Box>
				<Typography variant="body2" className={classes.bodyInfo}>{bannerConstants.BODY_TITLE}</Typography>
				<CopyText
					successMessage="Ascension email address has been copied"
					className={classes.copyTextClass}
					typographyProps={{ variant: 'body2', color: 'primary', className: classes.copyTextClass }}
					iconButtonProps={{ className: classes.copyIcon }}
				>
					{adEmail}
				</CopyText>
			</Box>}
		</Banner>
	)
};
