import React from 'react';
import { IconButton, Typography, Box, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

interface BannerProps {
	img: React.FC;
	title: string;
	className?: string;
	onClose?: () => void;
}
const useStyles = makeStyles((theme) => ({
	titleText: {
		color: theme.palette.error['600'],
	},
	horizontalRule: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	bannerContainer: {
		display: 'flex',
		height: 150,
		padding: '0px 24px',
		backgroundColor: theme.palette.error['50'],
		borderRadius: 12,
		margin: theme.spacing(3.75, 0, 0, 0),
		alignItems: 'center'
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexGrow: 1,
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	closeButton: {
		display: 'flex',
		marginLeft: 'auto',
		padding: 0,
	},
}));

export const Banner: React.FC<BannerProps> = ({ img: Img, title, onClose, className, children }) => {
	const classes = useStyles();

	return (
		<Box className={classNames(classes.bannerContainer, className)} data-field="app-authenticatedBanner-container">
			{/* Image (left side) */}
			<Img />
			{/* Text (right side) */}
			<Box className={classes.contentContainer}>
				{/* Title */}
				<Box className={classes.titleContainer}>
					<Typography variant="h3" className={classes.titleText}>{title}</Typography>
					{onClose && (
						<IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={onClose} data-field="app-authenticatedBanner-closeButton">
							<CloseIcon color="inherit"/>
						</IconButton>
					)}
				</Box>
				{children && <Divider className={classes.horizontalRule} />}
				{children}
			</Box>
		</Box>
	);
};
