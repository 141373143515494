import React from 'react';
import { Banner } from '@components/Banner/Banner';
import { bannerConstants, BannerEnabledOptions } from '@components/AuthenticatedBanner/bannerConstants';
import { SecurityAlertBadge } from '@components/SecurityAlertBadge/SecurityAlertBadge';

interface UnAuthenticatedBannerType {
	className?: string;
}

export const UnAuthenticatedBanner: React.FC<UnAuthenticatedBannerType> = ({ className }) => {
	const showBanner = window.config.LOGIN_BANNER_ENABLED === BannerEnabledOptions.ENABLED;

	if (!showBanner) {
		return null;
	}

	return (
		<Banner
			img={SecurityAlertBadge}
			title={bannerConstants.TITLE}
			className={className}
		/>
	)
};
